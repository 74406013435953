<template>
  <div>
    <van-nav-bar left-arrow>
      <template #left>
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          @click="$router.go(-1)"
          alt=""
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">忘记密码</span>
      </template>
    </van-nav-bar>
    <van-form>
      <van-field
        label-class="field_view"
        v-model="username"
        name="用户名"
        label="+63"
        placeholder="请输入手机号"
        label-width="48px"
      >
        <div
          style="display: flex; flex-direction: row"
          slot="label"
          @click="toCodeSelection(3)"
        >
          <span>{{ label }}</span>
          <van-icon
            name="arrow-down"
            style="margin-top: 6px; margin-left: 3px"
          />
        </div>
      </van-field>
      <van-field
        v-model="phoneCode"
        center
        clearable
        label="验证码"
        placeholder="请输入验证码"
        label-width="48px"
      >
        <template #button>
          <van-button
            class="get_code_btn"
            size="small"
            @click="captchaLogin(this)"
            >获取验证码</van-button
          >
        </template>
      </van-field>
      <van-button
        round
        block
        type="info"
        color="linear-gradient(to right, #01B15B, #49D0A2)"
        class="nex_password_view"
        @click="changeNewPassword"
        >下一步</van-button
      >
    </van-form>
  </div>
</template>

<script>
import { captcha_sendSms, update_password } from "@/service/api/index.js";
import { setToken } from "@/utils/auth.js";
export default {
  data() {
    return {
      username: "",
      phoneCode: "",
    };
  },
  computed: {
    label: {
      get() {
        return this.$route.query.code || "+63";
      },
    },
    countryCode: {
      get() {
        return this.$route.query.countryCode || "PH";
      },
    },
  },
  methods: {
    changeNewPassword() {
      update_password({
        areaCode: this.label,
        countryCode: this.countryCode,
        captchaCode: this.phoneCode,
        loginName: this.username,
        captchaId: this.captchaId,
      }).then((res) => {
        if (res.status == 200) {
          setToken(res.data, "token");
          this.$router.push("/changeNewPassword");
        }
      });
    },
    toCodeSelection(option) {
      this.$router.push({
        path: "/areaCodeSelection",
        query: { option: option ,form:"/forgetPassword"},
      });
    },
    captchaLogin(e) {
      captcha_sendSms({
        phone: this.username,
        areaCode: this.label.replace("+", ""),
        countryCode: this.countryCode,
      }).then((res) => {
        if (res.status == 200) {
          this.captchaId = res.data.id;
          var btn = document.querySelector(".get_code_btn");
          var sum = 60;
          var timer;
          timer = setInterval(() => {
            sum--;
            btn.innerHTML = sum + "s";
            if (sum === 0) {
              btn.innerHTML = "获取验证码";
              clearInterval(timer);
              sum = 60;
            }
          }, 1000);
        }
      });
      // updatePassword({ loginName: this.username, password: this.password })
      //   .then((res) => {})
      //   .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.get_code_btn {
  border: none;
  color: #00b05a;
}
.van-button--block {
  width: 90%;
  height: 35px;
  margin: 30px auto;
}
</style>